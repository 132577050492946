export const idlFactory = ({ IDL }) => {
  const GameStatus = IDL.Variant({
    'Finalized' : IDL.Null,
    'Active' : IDL.Null,
    'Complete' : IDL.Null,
    'Nonexistent' : IDL.Null,
  });
  const QueueEntry = IDL.Record({
    'player' : IDL.Principal,
    'name' : IDL.Text,
    'amount' : IDL.Nat,
  });
  const GameSessionResult = IDL.Record({
    'status' : GameStatus,
    'balances' : IDL.Vec(QueueEntry),
  });
  const GameObjectType = IDL.Variant({ 'Bomb' : IDL.Null, 'Ship' : IDL.Null });
  const Velocity = IDL.Record({ 'x' : IDL.Float64, 'y' : IDL.Float64 });
  const Acceleration = IDL.Record({ 'x' : IDL.Float64, 'y' : IDL.Float64 });
  const Position = IDL.Record({ 'x' : IDL.Float64, 'y' : IDL.Float64 });
  const InertialState = IDL.Record({
    'velocity' : Velocity,
    'mass' : IDL.Nat,
    'acceleration' : Acceleration,
    'position' : Position,
  });
  const PhysicalPresence = IDL.Record({
    'inertialState' : InertialState,
    'radius' : IDL.Nat,
  });
  const GameObject = IDL.Record({
    't' : GameObjectType,
    'id' : IDL.Nat,
    'cleanup' : IDL.Bool,
    'physicalPresence' : PhysicalPresence,
  });
  const BDBView = IDL.Record({
    'status' : GameStatus,
    'dataValues' : IDL.Vec(IDL.Nat),
    'tick' : IDL.Nat,
    'objects' : IDL.Vec(GameObject),
    'dataKeys' : IDL.Vec(IDL.Text),
  });
  const Vector = IDL.Record({ 'x' : IDL.Float64, 'y' : IDL.Float64 });
  const InputCommandTypes = IDL.Variant({
    'Fire' : IDL.Null,
    'None' : IDL.Null,
    'Thrust' : IDL.Null,
  });
  const InputCommand = IDL.Record({
    'player' : IDL.Nat,
    'vector' : Vector,
    'commandType' : InputCommandTypes,
  });
  const Result = IDL.Variant({ 'Ok' : IDL.Null, 'Err' : IDL.Null });
  const QueueValidation = IDL.Variant({
    'Invalid' : IDL.Null,
    'ValidReady' : IDL.Null,
    'ValidNotReady' : IDL.Null,
  });
  const QueueJoinResult = IDL.Record({
    'validity' : QueueValidation,
    'playerID' : IDL.Nat,
    'gameID' : IDL.Nat,
  });
  const GameRouter = IDL.Service({
    'gameSessionResult' : IDL.Func([IDL.Nat], [GameSessionResult], []),
    'gameStatus' : IDL.Func([IDL.Nat], [GameStatus], []),
    'getGameView' : IDL.Func([IDL.Nat], [BDBView], []),
    'getScores' : IDL.Func([IDL.Nat], [IDL.Vec(QueueEntry)], []),
    'getTick' : IDL.Func([IDL.Nat], [IDL.Nat], []),
    'input' : IDL.Func([IDL.Nat, IDL.Nat, InputCommand], [Result], []),
    'startGame' : IDL.Func([IDL.Vec(QueueEntry)], [IDL.Nat], []),
    'testGame' : IDL.Func([], [IDL.Nat], []),
    'validateQueue' : IDL.Func([IDL.Vec(QueueEntry)], [QueueJoinResult], []),
  });
  return GameRouter;
};
export const init = ({ IDL }) => { return []; };
