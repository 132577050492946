{
  "__Candid_UI": {
    "local": "br5f7-7uaaa-aaaaa-qaaca-cai"
  },
  "bulldogblast": {
    "local": "bkyz2-fmaaa-aaaaa-qaaaq-cai"
  },
  "frontend": {
    "local": "bd3sg-teaaa-aaaaa-qaaba-cai"
  },
  "lobby": {
    "local": "be2us-64aaa-aaaaa-qaabq-cai"
  }
}