export const idlFactory = ({ IDL }) => {
  const GameStatus = IDL.Variant({
    'Finalized' : IDL.Null,
    'Active' : IDL.Null,
    'Complete' : IDL.Null,
    'Nonexistent' : IDL.Null,
  });
  const QueueEntry = IDL.Record({
    'player' : IDL.Principal,
    'name' : IDL.Text,
    'amount' : IDL.Nat,
  });
  const GameQueue = IDL.Vec(QueueEntry);
  const QueueValidation = IDL.Variant({
    'Invalid' : IDL.Null,
    'ValidReady' : IDL.Null,
    'ValidNotReady' : IDL.Null,
  });
  const LobbyQueueJoinResult = IDL.Record({
    'validity' : QueueValidation,
    'playerID' : IDL.Nat,
    'gameID' : IDL.Nat,
  });
  const LobbyLoginResult = IDL.Record({ 'credits' : IDL.Nat });
  const Lobby = IDL.Service({
    'deposit' : IDL.Func([], [IDL.Nat], []),
    'gameStatus' : IDL.Func([IDL.Nat], [GameStatus], []),
    'getQueue' : IDL.Func([], [GameQueue], []),
    'joinQueue' : IDL.Func([IDL.Text], [LobbyQueueJoinResult], []),
    'login' : IDL.Func([], [LobbyLoginResult], []),
  });
  return Lobby;
};
export const init = ({ IDL }) => { return []; };
